import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const SuccessPage = () => (
  <Layout>
    <div style={{ textAlign: `center`, marginTop: `5em` }}>
      <h1>Your message has been submitted</h1>

      <br />
      <Link to="/">Go back to the homepage</Link>
    </div>
  </Layout>
)

export default SuccessPage
